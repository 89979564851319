export const CSS = ({ textColor }) => `
  .narvar__spinner {
    width: 16px;
    height: 16px;
    border: 3px solid #fff;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }
    @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .narvar__spinner--dark {
    border-color: #000;
    margin: 4px;
  }
  .narvar_banner_container {
    display: flex;
    width: 100%;
    justify-content: center;
    line-height: 1.8rem;
  }
  .narvar_step_container_center {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
  }
  .narvar_step_container_space {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  .narvar_options_container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
  .narvar_options_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 12px;
  }
  .primary_text {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.2rem;
  }
  .body_text {
    font-size: 1.3rem;
    line-height: 1.9rem;
  }
  .text_link {
    text-decoration: underline;
    cursor: pointer;
  }
  .confirm_text {
    margin: 0 1rem;
  }
  .text_left {
    text-align: left;
  }
  .success_text {
    width: 100%;
  }
  .back_button {
    line-height: 1.1rem;
  }
  .confirm_button {
    padding: 1rem 2rem;
    text-align: center;
    border-radius: 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    border-width: 0;
  }
  .refund_amount_container {
    text-align: center;
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    border-width: 1px;
    border-style: solid;
    margin-right: 1.5rem;
    font-size: 2.1rem;
  }
  @media (min-width: 500px) {
    .narvar_options_container {
      max-width: 500px;
    }
    .narvar_step_container_space {
      max-width: 500px;
    }
    .confirm_text {
      text-align: left;
    }
  }
`;
